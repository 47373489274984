import Amplify, { Auth } from 'aws-amplify';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Amplify.configure({
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_CDxQfCl4c',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '6i8nlv3c8bagffrsgrec1n3kuk',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: 'login.imaginary.software',
        scope: [
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin',
          'software.imaginary/license.offline',
          'software.imaginary/license.list',
          'software.imaginary/license.request',
          'software.imaginary/license.edit',
          'software.imaginary/license.delegate',
          'software.imaginary/license.revoke',
          'software.imaginary/license.admin',
          'software.imaginary/license.admin.listAll',
          'software.imaginary/license.admin.listAllUsers',
          'software.imaginary/license.admin.create',
          'software.imaginary/license.admin.revoke',
          'software.imaginary/license.admin.update',
          'software.imaginary/license.admin.delegate',
          'software.imaginary/license.admin.offline',
          'software.imaginary/license.admin.edit',
          'software.imaginary/license.admin.AddUserToGroup',
          'software.imaginary/license.admin.RemoveUserFromGroup',
          'software.imaginary/license.admin.ResetUserPassword',
          'software.imaginary/license.admin.EnableUser',
          'software.imaginary/license.admin.DisableUser'
        ],
        redirectSignIn: 'https://portal.carbonforunreal.com/',
        redirectSignOut: 'https://portal.carbonforunreal.com/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  });
} else if (window.location.host.match(/localhost/)) {
  Amplify.configure({
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_5wFTEoZiq',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: 'upg49vhub8464uf3vtr32tm2c',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: 'logindev.imaginary.software',
        scope: [
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin',
          'software.imaginary/license.offline',
          'software.imaginary/license.list',
          'software.imaginary/license.request',
          'software.imaginary/license.edit',
          'software.imaginary/license.delegate',
          'software.imaginary/license.revoke',
          'software.imaginary/license.admin',
          'software.imaginary/license.admin.listAll',
          'software.imaginary/license.admin.listAllUsers',
          'software.imaginary/license.admin.create',
          'software.imaginary/license.admin.revoke',
          'software.imaginary/license.admin.update',
          'software.imaginary/license.admin.delegate',
          'software.imaginary/license.admin.offline',
          'software.imaginary/license.admin.edit',
          'software.imaginary/license.admin.AddUserToGroup',
          'software.imaginary/license.admin.RemoveUserFromGroup',
          'software.imaginary/license.admin.ResetUserPassword',
          'software.imaginary/license.admin.EnableUser',
          'software.imaginary/license.admin.DisableUser'
        ],
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  });
} else {
  Amplify.configure({
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_5wFTEoZiq',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: 'upg49vhub8464uf3vtr32tm2c',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: 'logindev.imaginary.software',
        scope: [
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin',
          'software.imaginary/license.offline',
          'software.imaginary/license.list',
          'software.imaginary/license.request',
          'software.imaginary/license.edit',
          'software.imaginary/license.delegate',
          'software.imaginary/license.revoke',
          'software.imaginary/license.admin',
          'software.imaginary/license.admin.listAll',
          'software.imaginary/license.admin.listAllUsers',
          'software.imaginary/license.admin.create',
          'software.imaginary/license.admin.revoke',
          'software.imaginary/license.admin.update',
          'software.imaginary/license.admin.delegate',
          'software.imaginary/license.admin.offline',
          'software.imaginary/license.admin.edit',
          'software.imaginary/license.admin.AddUserToGroup',
          'software.imaginary/license.admin.RemoveUserFromGroup',
          'software.imaginary/license.admin.ResetUserPassword',
          'software.imaginary/license.admin.EnableUser',
          'software.imaginary/license.admin.DisableUser'
        ],
        redirectSignIn: 'https://d22ybajzgsz5sr.cloudfront.net/',
        redirectSignOut: 'https://d22ybajzgsz5sr.cloudfront.net/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  });
}

// You can get the current config object
Auth.configure();
